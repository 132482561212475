<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem1">
          <div class="listbox">
            <div class="name">{{name}}</div>
            <div class="unames">{{idnum}}</div>
            <div class="unames1">{{address1}}</div>
            <div class="unames1">{{mobile}}</div>           
            <div class="unames1">{{address2}}</div>
          </div>
          <div class="listbox1">
            <img :src="picture_url" class="iconkg" />
          </div>         
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">通行码</div>
            <div v-if="!flag">
              <img :src="picture_ewm_url" class="iconewm" />
              <div class="ll">
                <div class="txt">{{company}}扫码</div>           
              </div>
              <div class="ll">
                <div class="txt1">{{card_date}}</div>         
              </div>               
            </div>
            
            <div v-if="flag">
              <div class="list_ll list2">
                <div class="ll">
                  <div class="button primary mt" @click="submit">生成通行密码</div>         
                </div>       
              </div>
            </div>         
          </div>         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { openid } = this.$route.query;
    this.openid = openid;
    //alert(openid);
    let { data } = await this.axios.post(
      '/ctid/tlService/seluserewm',
      qs.stringify({
        openid: this.openid
      })
    );
    if (data.flag === '0') {
        //Toast(data.message);
        this.name = data.data.name;
        this.idnum = data.data.idnum;
        this.mobile = data.data.mobile;
        this.address1 = data.data.memo_2;
        this.address2 = data.data.memo_3;
        this.company = data.data.memo_1;
        var stmp = data.data.log_date;     
        this.card_date = stmp.substring(0,10); 
        this.picture_ewm_url = data.data.picture_ewm; 
        this.picture_url = data.data.picture_peo; 
        this.flag = false;
    }else{
      //Toast(data.message);
      Dialog.confirm({
        message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message+",是否离开！"
      }).then(async () => {
        window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
      });
    }
    
  },
  data() {
    return {
      data: {
        kgflag: false
      },
      openid:'',
      picture_url:'/img/zwzp.png',
      picture_ewm_url:'/img/wewm.png',
      name:'',
      idnum:'',
      mobile:'',
      address1:'',
      address2:'',
      company:'',
      card_date:'',
      flag: true
    };
  },
  methods: {
    
    async submit(){
      window.location.href = "http://ywfwpt.ywkj.online/ctid/tlService/ceatewm?openid="+this.openid;
      //this.flag = false;
      //Toast("aa");
      /*let { data } = await this.axios.post(
        '/ctid/tlService/ceatewm',
        qs.stringify({
          openid: this.openid
        })
      );
      if (data.flag === '0') {
          Toast(data.message);
          this.name = data.data.name;
          this.idnum = data.data.idnum;
          this.mobile = data.data.mobile;
          this.address1 = data.data.memo_2;
          this.address2 = data.data.memo_3;
          this.company = data.data.memo_1;
          var stmp = data.data.log_date;     
          this.card_date = stmp.substring(0,10);          
          this.picture_ewm_url = data.data.picture_ewm; 
          this.flag = false;
      }else{
        Toast(data.message);
      }*/
            
    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg {
      width: 100px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 370px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 60px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 225px;   
        height: 300px;  
        float: right;
        margin-right: 34px;
        margin-bottom: 34px;
        border-radius: 8px;
      }    
    }   
  }
  .listitem2 {
    overflow: hidden;
    height: 750px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }  
      .iconewm {
        margin-top: 20px;
        margin-left: 150px;
        width: 400px;   
        height: 400px;  
      }  
      
      .ll {
        width: 90%;
        margin: 0px auto;
        .txt {
          margin-top: 35px;
          width: 100%;
          height: 50px;
          text-align: center;
          overflow: hidden;
          font-size: 32px;
          line-height: 50px;
        }
        .txt1 {
          width: 100%;
          height: 50px;
          text-align: center;
          overflow: hidden;
          font-size: 32px;
          line-height: 50px;
        }
      }
      
    }

    
  }
  .listitem3 {
    overflow: hidden;
    height: 620px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 50px;
        margin-left: 300px;
      }  
      .name1 {
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 0px;
        margin-left: 175px;
      }
      .iconewm {
        margin-top: 20px;
        margin-left: 160px;
        width: 400px;   
        height: 400px;  
      }  
      
      .ll {
        width: 90%;
        margin: 0px auto;
      }
      
    }

    
  }
  
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>